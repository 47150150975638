<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'class-3'"
    :headerClass="'fixed-home'"
    :contentColor="'light'"
    :backButtonClass="'btn-1'"
  >
    <template v-slot:ion-content>
      <div class="wrap">
        <div class="image">
          <img src="../../assets/img/update-required.png" alt="" />
        </div>

        <h2>Update required</h2>
        <p>
          A new version of Uptrade is available. Click the playstore button
          below to update.
        </p>
        <div id="getItOnPlaystore" @click="goToPlaystore()">
          <img src="../../assets/img/google-play-badge.png" alt="" />
        </div>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import { arrowForward } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";

export default {
  inject: ["store"],
  data() {
    return {
      arrowForward,
    };
  },
  components: {
    "base-layout-2": BaseLayout2,
  },

  methods: {
    goToPlaystore() {
      window.location.href = this.appStoreUrl;
    },

    updateFromServer() {
      window.location.href = this.store.server;
    }
  },

  async ionViewDidEnter() {
    let self = this;

    //Unsubscribe from all topics.

    self.store.actions.setStatusBarStyleLight();

    this.store.loader(false);
  },
};
</script>


<style scoped lang="scss">
.page-container {
  border-top-left-radius: 40px;
  background: var(--ion-color-light);
  overflow: auto;
  padding-top: 50px;
}

.wrap {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image {
    padding: 20px;
    position: relative;
    width: 63%;
    max-width: 220px;

    img {
      max-width: unset;
      width: 100%;
      position: relative;
    }
  }

  h2 {
    font-weight: bold;
    text-align: center;
  }

  p {
    text-align: center;
  }
}

#getItOnPlaystore {
  width: 200px;

  img {
    width: 100%;
  }
}

.exit {
  display: block;
  margin-top: 20px;
  font-weight: 800;
  color: var(--ion-color-primary);
  text-decoration: none;
}
</style>